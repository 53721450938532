import React from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { OrderFoodOnlineSidebarCalculationBlock } from '../../features/order-food-online/order-food-online-sidebar-calculation-block'
import {
  Divider,
  OrderFoodOnlineSidebarCalculationKcalItem,
  OrderFoodOnlineSidebarCalculationPriceItem,
  OrderFoodOnlineSidebarCalculationPromoCodeItem,
  OrderFoodOnlineSidebarCalculationTotalBlockItem,
  OrderFoodOnlineSidebarCalculationTransportPerDayItem,
  OrderFoodOnlineSidebarCalculationTransportTotalItem,
  OrderFoodOnlineSidebarDPHPriceItem,
  OrderFoodOnlineSidebarPayOrderButtonItem,
  OrderFoodOnlineSidebarPriceItemWithDPH,
} from '../../features/order-food-online/order-food-online-sidebar-calculation-item'
import { PromoCodeInput } from '../../../../form/promo-code-input'
import { useGetters, useOrderFoodContext, useSetters } from '../../model'
import { FieldGroup, Label, Checkbox } from '../../components/form'
import styles from './order-food-delivery-sidebar.module.css'
import { OrderIcon } from '../../components/icons'
import { OrderFoodOnlineType } from '../../model/order-food-online-type'

export const OrderFoodDeliverySidebar = () => {
  const { t } = useTranslation()
  const getters = useGetters()
  const setters = useSetters()

  const [, setState] = useOrderFoodContext()

  const handlePromo = data => {
    setState({
      [OrderFoodOnlineType.PromoCode]: data.value,
      [OrderFoodOnlineType.PriceDiscount]: data.totalPrice,
      [OrderFoodOnlineType.Discount]: data.discount,
    })
  }

  const handleTerms1 = () => {
    setters.setOrderFoodDeliveryTerms1Toggle()
  }

  const handleTerms2 = () => {
    setters.setOrderFoodDeliveryTerms2Toggle()
  }

  return (
    <div className={styles.sidebar}>
      <header>
        <span className={cx(styles.headerIcon)}>
          <OrderIcon />
        </span>
        <h2>{t('forms.onlineOrderFormTitle4')}</h2>
        <p>Pokračujte prosím vyplněním kontaktních údajů.</p>
      </header>

      {/* <div className={styles.resultBlock}>
        <OrderFoodOnlineSidebarProgramBlock />
      </div> */}

      <div className={styles.mobile}>
        <FieldGroup>
          <Label>{t('forms.onlineOrderFormPromoCodeLabel2')}</Label>
          <PromoCodeInput
            placeholder="Promokód"
            totalPrice={getters.getOrderFoodPriceByDays}
            onChange={handlePromo}
            isPromoApplyed={!!getters.getOrderFoodPromoCode}
          />
        </FieldGroup>
      </div>

      <div className={styles.resultBlock}>
        <OrderFoodOnlineSidebarCalculationBlock>
          <OrderFoodOnlineSidebarCalculationPriceItem size="sm" />
          <Divider />
          <OrderFoodOnlineSidebarCalculationKcalItem size="sm" />
          {getters.isOrderFoodDeliveryFree ? null : (
            <>
              <Divider />
              <OrderFoodOnlineSidebarCalculationTransportPerDayItem size="sm" />
              <Divider />
              <OrderFoodOnlineSidebarCalculationTransportTotalItem size="sm" />
            </>
          )}
          {getters.isCompanyOrder && (
            <>
              <Divider />
              <OrderFoodOnlineSidebarDPHPriceItem size="sm" />
              <Divider />
              <OrderFoodOnlineSidebarPriceItemWithDPH size="sm" />
            </>
          )}
          {!!getters.getOrderFoodDiscount && (
            <>
              <Divider />
              <OrderFoodOnlineSidebarCalculationPromoCodeItem size="sm">
                {getters.getOrderFoodDiscount &&
                  `-${getters.getOrderFoodDiscount}`}
              </OrderFoodOnlineSidebarCalculationPromoCodeItem>
            </>
          )}
          <Divider />
          <OrderFoodOnlineSidebarCalculationTotalBlockItem size="lg">
            {numberFormat(getters.getOrderFoodTotalPrice)}
          </OrderFoodOnlineSidebarCalculationTotalBlockItem>
        </OrderFoodOnlineSidebarCalculationBlock>
      </div>

      <div className={styles.resultBlock}>
        <div className={styles.desktop}>
          <FieldGroup>
            <Label>{t('forms.onlineOrderFormPromoCodeLabel2')}</Label>
            <PromoCodeInput
              placeholder="Promokód"
              totalPrice={getters.getOrderFoodPriceByDays}
              onChange={handlePromo}
              isPromoApplyed={!!getters.getOrderFoodPromoCode}
            />
          </FieldGroup>
        </div>

        <FieldGroup>
          <Checkbox
            name="terms1"
            checked={getters.isOrderFoodDeliveryTerms1}
            onClick={handleTerms1}
          >
            {t('forms.mainFormCheckTerms1Label')}{' '}
            <a href="/terms/" target="_blank">
              {t('forms.mainFormCheckTerms2Label')}
            </a>
            .
          </Checkbox>
          <Checkbox
            name="terms2"
            checked={getters.isOrderFoodDeliveryTerms2}
            onClick={handleTerms2}
          >
            {t('forms.mainFormCheckTerms3Label')}{' '}
            <a href="/gdpr/" target="_blank">
              {t('forms.mainFormCheckTerms4Label')}
            </a>
          </Checkbox>
        </FieldGroup>
      </div>

      <OrderFoodOnlineSidebarPayOrderButtonItem
        disabled={!getters.isOrderFoodDeliveryValid}
      />
      {/* <span className={styles.sidebarBackground} /> */}
    </div>
  )
}

const numberFormat = value => {
  if (!value) return value
  return (value || '')?.toLocaleString().replace(',', ' ')
}
