import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { FormProvider, useForm } from 'react-hook-form'

import { useModalContext } from '../../../../../shared/lib/react-context-modal'
import { clientApi } from '../../../../../shared/api'
import {
  isLocaleStorageEmpty,
  useLocalStorage,
} from '../../../../../shared/lib/local-storage'
import { AffiliateContext } from '../../../../../contexts/AffiliateContext'
import { OrderStorageType, useGetters, useSetters } from '../../model'
import {
  Form,
  FieldGroup,
  SelectCalendarButton,
  SelectTimeButton,
  Checkbox,
} from '../../components/form'
import {
  ApplyPayIcon,
  BankIcon,
  CardIcon,
  GoPayGreyIcon,
  PayPalIcon,
} from '../../components/icons/payment'
import { BackButton } from '../../components/back-button'
import { OrderFoodDeliveryTemplate } from '../../components/templates/order-food-delivery-template'
import { OrderFoodDeliverySidebar } from './order-food-delivery-sidebar'
import { OrderFoodDeliveryTimeModal } from './order-food-delivery-time-modal'
import { OrderFoodDeliveryCalendarModal } from './order-food-delivery-calendar-modal'
import styles from '../../components/templates/order-food-delivery-template.module.css'
import {
  checkCity,
  removeAfterSpace,
  sendCityValues,
  formatTimeRange,
} from '../../../../../helpers'

const OrderFoodContactInformationView = props => {
  const { onSubmit } = props

  const { t } = useTranslation()

  const { showModal } = useModalContext()
  const [orderContactStorage] = useLocalStorage(
    OrderStorageType.orderFoodContact,
    {}
  )
  const cityValue = orderContactStorage?.city?.label
  const cityId = orderContactStorage?.city?.value
  const cityCode = orderContactStorage?.city?.code
  const city = removeAfterSpace(cityValue)

  const getters = useGetters()
  const setters = useSetters()

  const handleCalendar = () => {
    showModal(OrderFoodDeliveryCalendarModal)
  }

  const handleTime = () => {
    showModal(OrderFoodDeliveryTimeModal)
  }

  const handlePayPal = () => {
    setters.setOrderFoodDeliveryPaymentPayPalToggle()
  }

  const handleApplePay = () => {
    setters.setOrderFoodDeliveryPaymentApplePayToggle()
  }

  const handleCard = () => {
    setters.setOrderFoodDeliveryPaymentCardToggle()
  }

  const handleBank = () => {
    setters.setOrderFoodDeliveryPaymentBankToggle()
  }
  const isShowModal = checkCity(cityCode ? cityCode : cityId)
  const isEn = document.location.pathname.includes('/en')
  const back = isEn
    ? '/en/order-food-online/order-food-contact-information'
    : '/order-food-online/order-food-contact-information'
  return (
    <Form onSubmit={onSubmit}>
      <OrderFoodDeliveryTemplate
        back={
          <BackButton to={back}>{t('home.orderFoodOnline.backBtn')}</BackButton>
        }
        sidebar={<OrderFoodDeliverySidebar />}
      >
        <div className={styles.content}>
          <header className={styles.desktop}>
            <h2>{t('home.orderFoodOnline.step3.title')}</h2>
            <p>{t('home.orderFoodOnline.step3.subtitle')}</p>
          </header>
          <div className={styles.selectBlock}>
            <header className={styles.header}>
              <h3>
                <span>{t('home.orderFoodOnline.step3.startDateTitle')}</span>
              </h3>
              <p>{t('home.orderFoodOnline.step3.startDateLabel')}</p>
            </header>
            <div className={styles.formContainer}>
              <div className={styles.formBlock}>
                <FieldGroup>
                  <SelectCalendarButton
                    value={dateFormat(getters.getOrderFoodDeliveryCalendar)}
                    onClick={handleCalendar}
                  >
                    {t('home.orderFoodOnline.step3.calendarBtnText')}
                  </SelectCalendarButton>
                </FieldGroup>
              </div>
            </div>
            {isShowModal && (
              <>
                <div className={styles.divider} />
                <header className={styles.header}>
                  <h3>
                    <span>
                      {t('home.orderFoodOnline.step3.deliveryTimeTitle')}
                    </span>
                  </h3>
                  <p>
                    {t(`home.orderFoodOnline.step3.deliveryTimeLabel.${city}`)}
                  </p>
                </header>
                <div className={styles.formContainer}>
                  <div className={styles.formBlock}>
                    <FieldGroup>
                      <SelectTimeButton
                        value={getters.getOrderFoodDeliveryTimeFormatted}
                        onClick={handleTime}
                      >
                        {t('home.orderFoodOnline.step3.timeBtnText')}
                      </SelectTimeButton>
                    </FieldGroup>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={styles.selectBlock}>
            <header className={styles.header}>
              <h3>
                <span>{t('home.orderFoodOnline.step3.paymentMethod')}</span>
              </h3>
            </header>
            <div>
              <FieldGroup>
                <ul className={styles.payments}>
                  {/* <li onClick={handlePayPal}>
                    <span style={{ pointerEvents: 'none' }}>
                      <Checkbox
                        name="paypal"
                        checked={getters.isOrderFoodDeliveryPaymentPayPal}
                      />
                    </span>
                    <span>
                      <PayPalIcon />
                    </span>
                    <span>PayPal</span>
                  </li> */}
                  {/* <li onClick={handleApplePay}>
                    <span style={{ pointerEvents: 'none' }}>
                      <Checkbox
                        name="applypay"
                        checked={getters.isOrderFoodDeliveryPaymentApplePay}
                      />
                    </span>
                    <span>
                      <ApplyPayIcon />
                    </span>
                    <span>Apple Pay</span>
                  </li> */}
                  <li
                    onClick={!getters.isCompanyOrder ? handleCard : null}
                    style={{
                      opacity: getters.isCompanyOrder ? '0.5' : '1',
                    }}
                  >
                    <span
                      style={{
                        pointerEvents: 'none',
                        height: '16px',
                      }}
                    >
                      <Checkbox
                        name="card"
                        checked={getters.isOrderFoodDeliveryPaymentCard}
                        disabled={getters.isCompanyOrder}
                      />
                    </span>
                    <span>
                      <CardIcon name="paypal" />
                    </span>
                    <span>{t('home.orderFoodOnline.step3.card')}</span>
                  </li>
                  <li onClick={handleBank}>
                    <span style={{ pointerEvents: 'none', height: '16px' }}>
                      <Checkbox
                        name="bank"
                        checked={getters.isOrderFoodDeliveryPaymentBank}
                        onClick={handleBank}
                      />
                    </span>
                    <span>
                      <BankIcon />
                    </span>
                    <span>{t('home.orderFoodOnline.step3.transfer')}</span>
                  </li>
                </ul>
              </FieldGroup>
              <p className={styles.payText}>
                <span>{t('home.orderFoodOnline.step3.paymentText')}</span>
                <span>
                  <GoPayGreyIcon />
                </span>
              </p>
            </div>
          </div>
        </div>
      </OrderFoodDeliveryTemplate>
    </Form>
  )
}

export const OrderFoodDelivery = () => {
  const getters = useGetters()
  const setters = useSetters()

  const affiliateContext = useContext(AffiliateContext)
  const [orderOnlineStorage] = useLocalStorage(
    OrderStorageType.orderFoodOnline,
    {}
  )
  const [orderContactStorage] = useLocalStorage(
    OrderStorageType.orderFoodContact,
    {}
  )

  const methods = useForm({
    defaultValues: {},
  })

  const { affiliateId } = affiliateContext?.affiliate || {}
  const isEn = document.location.pathname.includes('/en')
  const back = isEn ? '/en/order-food-delivery' : '/order-food-delivery'
  useEffect(() => {
    if (!isLocaleStorageEmpty(orderOnlineStorage)) {
      setters.setState(orderOnlineStorage)
    }
    if (isLocaleStorageEmpty(orderOnlineStorage)) {
      window.location.href = back
    }
  }, [])

  const handleSubmit = async () => {
    const testovani = localStorage.getItem('new-online-form-version')
    const isEn = document.location.pathname.includes('/en')
    const citiesTime = sendCityValues(
      orderContactStorage.city.code
        ? orderContactStorage.city.code
        : orderContactStorage.city.value
    )
    const deliveryCityTime = !citiesTime
      ? getters.getOrderFoodDeliveryTime
      : formatTimeRange(citiesTime)
    const exeptions = getters.getOrderExeptions
    const customerMenuBezRyb = getters.isOrderFoodCustomerMenuBezRib
    const findExeptions = exeptions.find(menu => menu.title === 'RYBY')._id
    try {
      const newData = {
        ...orderOnlineStorage,
        ...orderContactStorage,

        email: orderContactStorage.email,
        checkTerms: getters.isOrderFoodDeliveryTerms1,
        checkTerms2: getters.isOrderFoodDeliveryTerms2,

        price: getters.getOrderFoodTotalPrice,
        promo: getters.getOrderFoodPromoCode,

        numberOfMeals: getters.getOrderFoodNumberOfMeals, // mealsPerDay ?
        length: getters.getOrderProgramDurationInDays,
        kcal: getters.getOrderNumberOfKcal,

        isOrderFoodWeekMeal5DaysLength: getters.isOrderFoodWeekMeal5DaysLength,
        isOrderFoodWeekMeal6DaysLength: getters.isOrderFoodWeekMeal6DaysLength,
        size: getters.isOrderFoodWeekMeal6DaysLength ? 'long' : 'short',

        date: getters.getOrderFoodDeliveryCalendar,
        deliveryTime: deliveryCityTime,

        classMenu: getters.isOrderFoodCustomerMenuVege
          ? 'Vegetarian'
          : 'Normal',

        isCompany: getters.isCompanyOrder,
        companyAddress: orderContactStorage.companyOrderAddress,
        city: orderContactStorage.city.value,
        companyName: orderContactStorage.companyOrderCompany,
        zip: orderContactStorage.companyOrderZip,
        regNumber: orderContactStorage.companyOrderIco,
        vatNumber: orderContactStorage.companyOrderDic,
        exeptions: customerMenuBezRyb ? [findExeptions] : [],
        affiliateId,

        onlinePayment: getters.isOrderFoodDeliveryPaymentCard, // onlinePayment === 'card'
      }
      const testovaniOrder = localStorage.getItem('website-version')
      const newSendData = {
        ...newData,
        testovani: testovaniOrder,
        city:orderContactStorage.city.label,
        price_daily:getters.getOrderFoodPrice
      }

      localStorage.setItem('order-info', JSON.stringify(newSendData))
      localStorage.setItem('FormName', 'online-order')

      const result = await clientApi.order(newData)

      if (result?.errors?.length) {
        if (result.errors[0]?.message) {
          alert(`Error! ${result.errors[0]?.message}`)
        } else {
          window.location.href =
            '/order-food-online/thank-you-payment-not-completed'
        }
        return false
      } else {
        const paymentRedirectURL = await result?.onlinePayment?.gw_url
        if (paymentRedirectURL) {
          console.log('===== paymentRedirectURL', paymentRedirectURL)
          window.location.href = paymentRedirectURL
        } else if (affiliateId) {
          const getParamsData = await Object.entries(result)
            .filter(item => item[0] !== 'onlinePayment')
            .map(([key, value]) => `${key}=${value || 0}`)
            .reduce(
              (previousValue, currentValue) =>
                `${previousValue}&${currentValue}`
            )
          console.log(
            'Affiliate: meals + onlinePayment + getParamsData',
            newData.numberOfMeals,
            newData.onlinePayment,
            getParamsData
          )
          window.location.href = isEn
            ? `/en/thank-you-order/?itemId=${newData.numberOfMeals.length}&onlinePayment=${newData.onlinePayment}${getParamsData}&actionTrackerId=431057`
            : `/thank-you-order/?itemId=${newData.numberOfMeals.length}&onlinePayment=${newData.onlinePayment}&${getParamsData}&actionTrackerId=431057`
        } else {
          console.log('NOT Affiliate redirect')
          window.location.href = isEn ? '/en/thank-you' : '/dekovacka-testdrive'
        }
        window.dataLayer.push({
          event: 'ga.pageview',
          pageURL: isEn ? '/en/thank-you' : '/dekovacka-testdrive',
          pageType: 'Purchase',
          testovani: testovani || null,
        })
        // if (!paymentRedirectURL) {
        //   window.location.href = '/order-food-online/thank-you-order-received'
        // }
      }
    } catch (error) {
      console.log('error:', error)
      // window.location.href =
      //   '/order-food-online/thank-you-payment-not-completed'
    }
  }
  useEffect(() => {
    const userData = localStorage.getItem('order-food-contact-information')
    const data = JSON.parse(userData)

    const name = data?.name
    const phone = data?.phone
    const email = data?.email
    const zip = data?.deliveryAddress?.components?.postcode
      ? data?.deliveryAddress?.components?.postcode
      : ''
    const city = data?.city?.label ? data?.city?.label : ''
    const state = 'CZ'

    let firstName = ''
    let lastName = ''

    if (name) {
      const nameParts = name.split(' ')
      firstName = nameParts[0]
      lastName = nameParts.slice(1).join(' ')
    }

    const userInfo = {
      firstName,
      lastName,
      phone,
      email,
      zip,
      city,
      state,
    }

    window.dataLayer &&
      window.dataLayer.push({
        event: 'step_3_order_food_delivery',
        pageURL: isEn ? '/en/thank-you' : '/thank-you',
        pageType: 'Purchase',
        userInfo,
      })
  }, [])

  return (
    <FormProvider {...methods}>
      {!isLocaleStorageEmpty(orderOnlineStorage) && (
        <OrderFoodContactInformationView
          onSubmit={methods.handleSubmit(handleSubmit)}
          methods={methods}
          back={'/#calculator'}
        />
      )}
    </FormProvider>
  )
}

const dateFormat = date => {
  if (!date) {
    return undefined
  }
  return new Date(date)?.toLocaleDateString()?.replace(/\//g, '.')
}
