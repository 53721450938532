import React from 'react'

import goPay from './go-pay.svg'
import goPayGrey from './go-pay-grey.svg'
import maestro from './maestro.svg'
import masterCard from './master-card.svg'
import visa from './visa.svg'
import applyPay from './apple-pay.svg'
import bank from './bank.svg'
import card from './card.svg'
import payPal from './paypal.svg'

export const GoPayIcon = ({ children, selected }) => {
  return <img src={goPay} alt="" />
}

export const GoPayGreyIcon = ({ children, selected }) => {
  return <img src={goPayGrey} alt="" />
}

export const MaestroIcon = ({ children, selected }) => {
  return <img src={maestro} alt="" />
}

export const MasterCardIcon = ({ children, selected }) => {
  return <img src={masterCard} alt="" />
}

export const VisaIcon = ({ children, selected }) => {
  return <img src={visa} alt="" />
}

export const ApplyPayIcon = ({ children, selected }) => {
  return <img src={applyPay} alt="" />
}

export const BankIcon = ({ children, selected }) => {
  return <img src={bank} alt="" />
}

export const CardIcon = ({ children, selected }) => {
  return <img src={card} alt="" />
}

export const PayPalIcon = ({ children, selected }) => {
  return <img src={payPal} alt="" />
}
