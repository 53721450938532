import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'
import { useSetters } from '../../model'
import { Calendar } from '../../components/calendar'
import { Button2 } from '../../../../../components/button2'
import { ModalCalendarTemplate } from '../../components/templates/modal-calendar-template'
import styles from './order-food-delivery-calendar-modal.module.css'

const DateType = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAYS: 6,
}

export const OrderFoodDeliveryCalendarModal = props => {
  const setters = useSetters()
  const { t } = useTranslation()
  
  const today = new Date();
  
  const possibleDeliverDate = calculateDeliveryDate(today)

  const [date, setDate] = useState(possibleDeliverDate)
  const minDate = createMinDate(date)
  const maxDate = createMaxDate(date)
  const startDate = getStartDate(date)

  const handleClick = () => {
    setters.setOrderFoodDeliveryCalendar(convertToUTC(date).toISOString())
    props.hideModal()
  }
  const convertToUTC = date => {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  }
  return (
    <ModalCalendarTemplate
      {...props}
      title={<h2>{t('home.orderFoodOnline.step3.startDateTitle')}</h2>}
    >
      <Calendar
        onChange={setDate}
        selectRange={false}
        defaultValue={date}
        maxDetail="month"
        locale="cs"
        minDate={minDate}
        maxDate={maxDate}
        tileDisabled={({ activeStartDate, date, view }) =>
          dateFilterCalendarDisabledByDays({ activeStartDate, date, view })
        }
      />
      <p className={styles.date}>
        <strong>{dateFormat(date)}</strong>
      </p>
      <div className={styles.button}>
        <Button2 color="secondary" onClick={handleClick}>
          {t('home.orderFoodOnline.step3.confirmDate')}
        </Button2>
      </div>
    </ModalCalendarTemplate>
  )
}

const dateFormat = date => {
  return date.toLocaleDateString().replace(/\//g, '.')
}

const createMinDate = date => {
  return getStartDay(date)
}

const createMaxDate = date => {
  const newDate = new Date(date)
  newDate.setFullYear(date.getFullYear() + 1)
  return newDate
}

const dateFilterCalendarDisabledByDays = ({ activeStartDate, date, view }) => {
  if (view === 'year') {
    return false;
  }
  return !(
    date.getDay() === DateType.MONDAY ||
    date.getDay() === DateType.WEDNESDAY ||
    date.getDay() === DateType.FRIDAY
  )
}

function getStartDate(date) {
  if ([2, 4, 6].indexOf(moment.utc().isoWeekday()) !== -1) {
    return new Date(date.getFullYear(), date.getMonth(), 6)
  } else if ([1, 3, 5].indexOf(moment.utc().isoWeekday()) !== -1) {
    return new Date(date.getFullYear(), date.getMonth(), 7)
  } else if ([7].indexOf(moment.utc().isoWeekday()) !== -1) {
    return new Date(date.getFullYear(), date.getMonth(), 5)
  }
}

function getStartDay() {
  let initial = moment.utc().add(3, 'days')
  if ([2, 4, 6].indexOf(moment.utc().isoWeekday()) !== -1) {
    initial = moment
      .utc()
      .add(6, 'days')
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .toDate()
  } else if ([1, 3, 5].indexOf(moment.utc().isoWeekday()) !== -1) {
    initial = moment
      .utc()
      .add(7, 'days')
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .toDate()
  } else if ([7].indexOf(moment.utc().isoWeekday()) !== -1) {
    initial = moment
      .utc()
      .add(5, 'days')
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .toDate()
  }

  return initial
}

// function getStartDay() {
//   let initial = moment.utc().add(3, 'days');

//   if ([1, 3, 5].indexOf(initial.isoWeekday()) !== -1) {
//     initial.add(6, 'days').startOf('day');
//   } else if ([6].indexOf(initial.isoWeekday()) !== -1) {
//     initial.add(5, 'days').startOf('day');
//   } else if ([7].indexOf(initial.isoWeekday()) !== -1) {
//     initial.add(4, 'days').startOf('day');
//   }

//   return initial.toDate();
// }
function calculateDeliveryDate(selectedDate) {
  const dayOfWeek = selectedDate.getDay();
  if (dayOfWeek <= 2 && dayOfWeek !== 0) { // Monday, Tuesday
    const daysUntilDelivery = 1 + 7 - dayOfWeek;
    return new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + daysUntilDelivery);
  }
  else if (dayOfWeek === 3 || dayOfWeek === 4) { // Wednesday, Thursday
    const daysUntilDelivery = 3 + 7 - dayOfWeek;
    return new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + daysUntilDelivery);
  }
  else if (dayOfWeek === 5) { // Friday
    const daysUntilDelivery = 5 + 7 - dayOfWeek;
    return new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + daysUntilDelivery);
  }
  else if (dayOfWeek === 6) { // Saturday
    const daysUntilDelivery = 6;
    return new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + daysUntilDelivery);
  }
  else { // Sunday
    const daysUntilDelivery = 5;
    return new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + daysUntilDelivery);
  }
}