import React from 'react'

import { IconButton } from '../../../../../components/icon-button'
import { CloseXIcon } from '../icons'
import { Modal } from '../modal'
import styles from './modal-time-template.module.css'

export const ModalTimeTemplate = (props) => {
  const { children, title } = props

  return (
    <Modal {...props}>
      <div className={styles.modal}>
        <div className={styles.template}>
          <div className={styles.close}>
            <IconButton onClick={() => props.hideModal()}>
              <CloseXIcon />
            </IconButton>
          </div>
          <div>
            <header className={styles.header}>
              <span className={styles.title}>{title}</span>
            </header>
            <div className={styles.items}>{children}</div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
