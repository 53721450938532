import React from 'react'
import cx from 'classnames'

import { Container } from '../../../../../components/container'
import { Steps } from '../steps'
import styles from './order-food-delivery-template.module.css'
import { LogoIcon } from '../icons'
import styles2 from './order-food-contact-information-template.module.css'

export const OrderFoodDeliveryTemplate = props => {
  const { sidebar, back } = props

  return (
    <section className={styles.section}>
      <div className={cx(styles2.backBlock, styles.mobile)}>{back}</div>
      <Steps step={3} />

      <Container className={cx('text-center', styles.container)}>
        <div className={styles.formWrapContainer}>
          <div className={styles.formInnerContainer}>
            <div className={styles.grid}>
              <div className={styles.selectColumn}>
                <span className={styles2.sidebarBackground} />

                <div className={cx(styles.backBlock, styles.desktop)}>
                  {back}
                </div>
                <div>{props.children}</div>
                <div className={styles.mobile}>
                  <div className={styles.content}>
                    <div className={styles.selectBlock}>{sidebar}</div>
                  </div>
                </div>
              </div>
              <div className={styles.resultColumn}>
                <div className={cx(styles.backBlock, styles.hidden)}>
                  {back}
                </div>
                <div className={styles.desktop}>{sidebar}</div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
