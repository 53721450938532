import React from 'react'
import { useTranslation } from 'react-i18next'

import { ModalTimeTemplate } from '../../components/templates/modal-time-template'
import { SelectButton } from '../../components/form'
import { getOrderFoodDeliveryTimeData } from '../../model/order-food-delivery-data'
import { OrderStorageType, useGetters, useSetters } from '../../model'
import { useLocalStorage } from '../../../../../shared/lib/local-storage'
import { removeAfterSpace } from '../../../../../helpers'

const CITY_VALUE = 'praha'

export const OrderFoodDeliveryTimeModal = props => {
  const setters = useSetters()
  const { t } = useTranslation()
  const [orderContactStorage] = useLocalStorage(
    OrderStorageType.orderFoodContact,
    {}
  )
  const cityValue = orderContactStorage?.city?.label
  const isPragueArea = cityValue === 'Okolí Prahy'
  const city = removeAfterSpace(cityValue);
  const handleClick = data => () => {
    setters.setOrderFoodDeliveryTime(data.map(x => x.toISOString()))
    props.hideModal()
  }

  return (
    <ModalTimeTemplate
      {...props}
      title={
        <>
          <h2>{t('home.orderFoodOnline.step3.deliveryTimeTitle')}</h2>
          <p>{t('home.orderFoodOnline.step3.timeSelectLabel')}</p>
        </>
      }
    >
      {getOrderFoodDeliveryTimeData(isPragueArea ? 'prahaArea' : city ? city : CITY_VALUE).map((x, index) => {
        const [from, to] = x

        return (
          <SelectButton onClick={handleClick(x)} key={index}>
            {format(from)} - {format(to)}
          </SelectButton>
        )
      })}
    </ModalTimeTemplate>
  )
}

const format = date => {
  const hours = date.getHours()
  const minutes = date.getMinutes().toString().padStart(2, '0')
  return `${hours}:${minutes}`
}
